<template>
  <div>
    <div id="map" style="width: 100%; height: 750px"></div>
  </div>
</template>
<script>
import TMap from "@/utils/initMap";
export default {
  data() {
    return {};
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // 初始化地图
    initMap() {
      TMap.init().then((TMap) => {
        var center = new TMap.LatLng(34.800931566274116, 113.56453645559719);
        //初始化地图
        var map = new TMap.Map("map", {
          zoom: 12, //设置地图缩放级别
          center: center, //设置地图中心点坐标
        });
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            jinsuolu: new TMap.MarkerStyle({
              width: 70, // 样式宽
              height: 90, // 样式高
              src: require('@/assets/lianxi/jinsuolu.png'),
            }),
            tianjianhu: new TMap.MarkerStyle({
              width: 70, // 样式宽
              height: 90, // 样式高
              src: require('@/assets/lianxi/tianjianhu.png'),
            }),
          },
          geometries: [
            {
              id:'jinsuolu',
              styleId: 'jinsuolu',
              position: center,
            },
            {
              id:'tianjianhu',
              styleId: 'tianjianhu',
              position: new TMap.LatLng(34.82445938630299, 113.4872592822943),
            },
          ],
        });
        var infoWindow = new TMap.InfoWindow({
                map: map,
                position: center,
                offset: { x: 200, y: 100 } //设置信息窗相对position偏移像素
            });
            infoWindow.close();//初始关闭信息窗关闭
            //监听标注点击事件
            marker.on("mouseover",  (evt)=> {
                //设置infoWindow
                infoWindow.open(); //打开信息窗
                infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                let neirong = ''
                let title =''
                let address = ''
                if(evt.geometry.id == 'jinsuolu'){
                  title = this.$t('guanyu.laochang')
                  address = this.$t('guanyu.laochangaddress')
                  neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/jinsuolu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                }else{
                  title = this.$t('guanyu.xinchang')
                  address = this.$t('guanyu.xinchangaddress')
                  neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/tianjianhu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                }
                neirong = neirong+"<div class='infotitle' >"+this.$t('theFreeCall')+"：400-0318-908</div><div class='infotitle' >"+this.$t('guanyu.theForeignTradeInAStraightLine')+"：0086-371-67981000</div><div class='infotitle' >"+this.$t('guanyu.phone')+"：0086-371-67988888/67981888</div><div class='infotitle' style='padding-bottom:20px'>"+address+"</div></div>"
                console.log(evt.geometry.id);
                infoWindow.setContent(neirong);//设置信息窗内容
        })
        
       
      });
    },
  },
};
</script>
 
<style scoped lang="scss">
.search {
  margin-bottom: 15px;
  margin-top: -20px;
}
.address {
  margin-top: 15px;
  margin-bottom: 10px;
  .el-button {
    padding: 0;
  }
}
.table {
  .el-button {
    padding: 0;
  }
}
</style>